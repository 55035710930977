import Page from 'components/common/Page';
import { TestimonialsSection } from 'components/landings/common/TestimonialsSection';
import { BlogSection } from 'components/landings/common/BlogSection';
import { ContentFaqSection } from 'components/common/sections';
import { MainActivity } from '../types';
import { BusinessPageHero } from './BusinessPageHero';
import { TeamSection } from './TeamSection';
import { PricingSection } from './PricingSection';
import { TrustBar } from './TrustBar';
import { ContactSection } from './ContactSection';
import { BenefitsSection } from './BenefitsSection';
import { TrustSection } from './TrustSection';

const TYPE = 'business';

const BusinessPage = () => {
  return (
    <Page breadcrumbs={false}>
      <BusinessPageHero type={TYPE} />

      <TrustBar />

      <PricingSection />

      <ContentFaqSection className="pt-0" sectionVariant="l" />

      <BenefitsSection type={TYPE} />

      <TestimonialsSection type={TYPE} />

      <TrustSection />

      <TeamSection />

      <ContactSection mainActivity={MainActivity.CarDealer} />

      <BlogSection />
    </Page>
  );
};

export { BusinessPage };
