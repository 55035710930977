import { Trans, useTranslation } from 'next-i18next';
import { Badge } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { useState, forwardRef } from 'react';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { useRouteData } from 'context/RouteDataProvider';
import { type FeatureId, isTranslationKeyValid } from 'modules/shared';
import type { Feature } from '../../types';
import { getFeatureIllustration } from '../../utils';

type FeatureArticleProps = Feature;

const FEATURE_COLOR: Record<FeatureId, string> = {
  damage: 'bg-blue-50',
  emissionTaxes: 'bg-red-50',
  emissions: 'bg-red-50',
  financialRestrictions: 'bg-blue-50',
  maintenance: 'bg-grey-50',
  mileage: 'bg-blue-50',
  naturalDisasters: 'bg-green-50',
  ownership: 'bg-blue-50',
  photos: 'bg-grey-50',
  registrationRestrictions: 'bg-blue-50',
  safety: 'bg-grey-50',
  specifications: 'bg-blue-50',
  stolenVehicleCheck: 'bg-yellow-100',
  timeline: 'bg-grey-50',
  titleCheck: 'bg-blue-50',
  usage: 'bg-blue-50',
  valuation: 'bg-green-50',
};

const FeatureArticle = forwardRef<HTMLDivElement, FeatureArticleProps>(({ index, id }, ref) => {
  const { t } = useTranslation();
  const { locale } = useRouteData();
  const [fallback, setFallback] = useState(false);
  const reversed = index % 2 !== 0;
  const illustration = getFeatureIllustration({ id, locale, compact: true });
  const illustrationSrc = fallback ? illustration.fallbackSrc : illustration.src;
  const badgeKey = isTranslationKeyValid(`features.${id}.alternativeTitle`)
    ? `features.${id}.alternativeTitle`
    : `features.${id}.title`;
  const titleKey = `features.${id}.extended.title`;
  const descriptionKey = `features.${id}.extended.description`;

  return (
    <article
      id={String(index)}
      className={cn(
        '-mx-2 grid grid-cols-1 gap-3 bg-blue-50 px-2 py-8 sm:px-4 md:mx-0 md:grid-cols-2 md:place-items-center md:gap-11 md:rounded-xl md:px-12 md:py-6',
        FEATURE_COLOR[id],
      )}
      ref={ref}
    >
      <div className={cn('flex max-sm:justify-center', reversed && 'md:col-start-2')}>
        <ArtDirectedImage
          className="object-contain"
          // @ts-expect-error TS(2739)
          srcSet={{
            forTabletPortraitUp: {
              src: illustrationSrc,
              width: 400,
              height: 273,
            },
            forDesktopBigUp: {
              src: illustrationSrc,
              width: 500,
              height: 413,
            },
          }}
          src={illustrationSrc}
          onError={() => setFallback(true)}
          quality={100}
          width={291}
          height={199}
        />
      </div>

      <div
        className={cn(
          'flex flex-col items-start gap-1.5',
          reversed && 'md:col-start-1 md:row-start-1',
        )}
      >
        {isTranslationKeyValid(badgeKey) && (
          <Badge variant="black" size="s">
            {t(badgeKey)}
          </Badge>
        )}

        <div className="flex flex-col gap-2">
          {isTranslationKeyValid(titleKey) && (
            <h3 className="text-h-xs">
              <Trans
                // @ts-expect-error TS(2339)
                t={t}
                i18nKey={titleKey}
                components={{
                  1: <em />,
                }}
              />
            </h3>
          )}

          {isTranslationKeyValid(descriptionKey) && (
            <p className="text-s">
              <Trans
                // @ts-expect-error TS(2339)
                t={t}
                i18nKey={descriptionKey}
                components={{
                  1: <strong className="text-s+" />,
                  2: (
                    <span className="flex h-2 content-['']">
                      <br />
                    </span>
                  ),
                }}
              />
            </p>
          )}
        </div>
      </div>
    </article>
  );
});

export { FeatureArticle };
