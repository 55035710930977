import { Button, Checkbox, LinkButton, Stack, StatusMessage, TextArea } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { cn } from '@carvertical/utils/styling';
import * as dataLayer from 'services/dataLayer';
import { FormField } from 'components/common/ui/forms';
import { useRouteData } from 'context/RouteDataProvider';
import { useRecaptcha } from 'modules/auth';
import { useEmailSubscription } from 'modules/subscription/hooks';
import { Link } from 'components/common/Link';
import type { ContactFormValues, MainActivity } from '../../types';
import { useB2bContactMutation } from '../../hooks/useB2bContactMutation';
import { Fields } from './Fields';
import { type ContactFormFields, contactFormSchema } from './schemas';
import styles from './ContactForm.module.scss';

type ContactFormProps = {
  mainActivity: MainActivity;
  variant?: 'vertical' | 'horizontal';
  onSubmit: () => void;
  ctaLabel?: string;
};

const SOURCE = 'B2B Contact Form';

const ContactForm = ({
  mainActivity,
  variant = 'horizontal',
  onSubmit,
  ctaLabel,
}: ContactFormProps) => {
  const { t } = useTranslation(['business', 'common']);
  const { getCaptchaResponse } = useRecaptcha();
  const { locale, market } = useRouteData();
  const {
    subscribe,
    subscribing,
    subscribed,
    subscriptionError,
    reset: subscriptionReset,
  } = useEmailSubscription();
  const {
    contactB2b,
    contactingB2b,
    b2bContacted,
    b2bContactError,
    reset: b2bContactReset,
  } = useB2bContactMutation();

  const form = useForm<ContactFormFields>({
    resolver: zodResolver(contactFormSchema),
  });
  const { formState, handleSubmit, watch } = form;
  const joinNewsletter = watch('joinNewsletter');
  const submitting = formState.isSubmitting || subscribing || contactingB2b;
  const success =
    formState.isSubmitSuccessful && b2bContacted && (joinNewsletter ? subscribed : true);
  const error = subscriptionError || b2bContactError;

  const submit = async (values: ContactFormValues) => {
    contactB2b({
      ...values,
      website: values.website || undefined,
      mainActivity,
      market: market.id,
      captchaResponse: await getCaptchaResponse(),
    });

    if (joinNewsletter) {
      subscribe({
        email: values.email,
        locale,
        market: market.id,
        source: `${SOURCE} - ${mainActivity}`,
      });
    }

    dataLayer.push('b2bFormSubmitted');
  };

  const getCtaLabel = () => {
    if (submitting) {
      return `${t('common:general.pleaseWaitLabel')}…`;
    }

    return ctaLabel ?? t('common:general.getInTouchActionFormal');
  };

  useEffect(() => {
    if (success) {
      onSubmit();
    }
  }, [success, onSubmit]);

  const Field = FormField<ContactFormFields>;

  return (
    <FormProvider {...form}>
      {error && (
        <StatusMessage
          closeButtonShown
          onCloseButtonClick={() => {
            subscriptionReset();
            b2bContactReset();
          }}
          title={t('common:general.errorTitle')}
          variant="error"
          className="mb-3"
        >
          {t('common:general.errorText')}
        </StatusMessage>
      )}

      <form
        onSubmit={handleSubmit(submit)}
        className={cn(styles.formWrapper, variant && styles[variant])}
      >
        <div className={styles.fieldsWrapper}>
          <Fields variant={variant} />
        </div>

        <Stack crossAxisAlign="stretch" className={styles.messageWrapper}>
          <Field name="message">
            <TextArea label={t('contactSection.placeholders.message')} rows={4} filled fullWidth />
          </Field>
        </Stack>

        <Stack
          gap={3}
          crossAxisAlign="center"
          mainAxisAlign="spaceBetween"
          type="horizontal"
          wrap
          className={styles.actionsWrapper}
        >
          <Field name="termsAccepted">
            <Checkbox
              label={
                <Trans
                  i18nKey="contactSection.termsAccepted"
                  t={t}
                  as="span"
                  components={{
                    1: (
                      <LinkButton
                        as={Link}
                        href="/policies"
                        target="_blank"
                        size="m"
                        variant="blue"
                      />
                    ),
                  }}
                />
              }
            />
          </Field>

          <Field name="joinNewsletter">
            <Checkbox label={t('contactSection.joinNewsletter')} />
          </Field>

          <Button
            className={cn('w-full sm:max-w-fit', variant === 'vertical' && 'mt-4')}
            variant="blue"
            type="submit"
            size={variant === 'vertical' ? 'm' : '2xl'}
            disabled={submitting}
          >
            {getCtaLabel()}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export { ContactForm };
