import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { noop, isString, flow } from 'lodash';
import classnames from 'classnames';
import { usePrevious } from 'react-use';
import YouTube from 'react-youtube';
import { Modal } from '@carvertical/ui';
import { getLanguage } from '../../utils/locale';
import withTranslate from '../../hoc/withTranslate';
import withRouteData from '../../hoc/withRouteData';
import PlayIcon from '../../assets/icons/play.svg';
import AspectRatioImage from './AspectRatioImage';
import styles from './Video.module.scss';

const Video = ({
  id,
  thumbnail,
  start,
  autoplay,
  playerVars,
  playedInModal,
  subtitlesShown,
  className,
  frameClassname,
  thumbnailClassname,
  aspectRatioRootClassName,
  aspectRatioClassName,
  buttonClassName,
  onStart,
  onClose,
  aspectRatio,
  renderIcon,
  routeData: { locale },
  t,
}) => {
  const [playing, setPlaying] = useState(false);

  const wasPlaying = usePrevious(playing);

  const play = () => setPlaying(true);
  const stop = () => setPlaying(false);

  useEffect(() => {
    if (playing && !wasPlaying) {
      onStart();
    }
  }, [playing, wasPlaying, onStart]);

  useEffect(() => {
    if (autoplay) {
      play();
    }
  }, [autoplay]);

  const close = async () => {
    stop();
    onClose();
  };

  const renderPlayIcon = () =>
    renderIcon ? renderIcon() : <PlayIcon className={styles.playIcon} original />;

  const modalPlayed = playedInModal && playing;

  return (
    <>
      {isString(thumbnail) ? (
        <div
          className={classnames(
            styles.frame,
            frameClassname && frameClassname,
            playing && styles.playing,
          )}
        >
          <AspectRatioImage
            ratio={aspectRatio}
            src={thumbnail}
            imageClassName={thumbnailClassname}
            className={aspectRatioRootClassName}
            aspectRatioClassName={aspectRatioClassName}
          />
          {!playing ? (
            <button
              type="button"
              className={classnames(styles.playButton, buttonClassName)}
              title={t('general.playVideoLabel')}
              onClick={play}
            >
              {renderPlayIcon()}
            </button>
          ) : (
            renderVideo()
          )}
        </div>
      ) : (
        thumbnail(play)
      )}

      <Modal open={modalPlayed} onClose={close} boxClassName="max-w-[1200px]">
        <div className={classnames(styles.frame, playing && styles.playing)}>
          <div className={styles.videoPlaceholder} />
          {renderVideo()}
        </div>
      </Modal>
    </>
  );

  function renderVideo() {
    return (
      <div className={classnames(styles.video, className)}>
        <YouTube
          className={styles.iframe}
          videoId={id}
          opts={{
            width: '100%',
            height: '100%',
            controls: 0,
            cc_load_policy: 0,
            fs: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            playerVars: {
              ...playerVars,
              ...(subtitlesShown && {
                cc_lang_pref: getLanguage(locale),
                cc_load_policy: 1,
              }),
              start,
              playlist: id,
            },
          }}
          autoplay
        />
      </div>
    );
  }
};

Video.propTypes = {
  id: PropTypes.string.isRequired,
  thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  aspectRatio: PropTypes.number,
  playerVars: PropTypes.shape(),
  autoplay: PropTypes.bool,
  start: PropTypes.number,
  playedInModal: PropTypes.bool,
  subtitlesShown: PropTypes.bool,
  className: PropTypes.string,
  frameClassname: PropTypes.string,
  thumbnailClassname: PropTypes.string,
  aspectRatioRootClassName: PropTypes.string,
  aspectRatioClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  onStart: PropTypes.func,
  onClose: PropTypes.func,
  renderIcon: PropTypes.func,
  routeData: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};
Video.defaultProps = {
  aspectRatio: 1.775,
  start: 0,
  playerVars: {},
  autoplay: false,
  playedInModal: false,
  subtitlesShown: false,
  className: undefined,
  frameClassname: undefined,
  thumbnailClassname: undefined,
  aspectRatioRootClassName: undefined,
  aspectRatioClassName: undefined,
  buttonClassName: undefined,
  onStart: noop,
  onClose: noop,
  renderIcon: undefined,
};

export default flow(withRouteData, withTranslate())(Video);
