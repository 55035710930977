import { find, kebabCase, mapValues, pickBy, toLower, reduce } from 'lodash';
import type { Market } from 'types/market';
import { markets } from 'config-ts';
import type { FeatureId } from 'modules/shared';
import { EXCLUDED_MARKETS } from 'constants/markets';
import type { PartialFeaturesRecord, Feature, FeatureIllustration } from './types';
import { COMPACT_IMAGE_PATH, IMAGE_PATH } from './constants';

type GetFeaturesParams = {
  countryCode?: string;
  featureKeys: FeatureId[];
  market: Market;
};

type GetFeatureIllustrationParams = {
  id: FeatureId;
  locale: string;
  compact?: boolean;
};

const getTags = (features: PartialFeaturesRecord) => Object.values(features).filter(Boolean);

const prepareFeatures = (featureKeys: FeatureId[]) =>
  reduce(
    featureKeys,
    (result, key) => {
      const feature: Feature = {
        index: 0,
        id: key,
      };
      return { ...result, [key]: feature };
    },
    {},
  ) as Record<FeatureId, Feature>;

const getFeatures = ({
  countryCode,
  featureKeys,
  market,
}: GetFeaturesParams): PartialFeaturesRecord => {
  const marketFeatures = EXCLUDED_MARKETS.includes(market.id)
    ? find(markets, { countryCode })?.features
    : market.features;
  const features = marketFeatures ?? markets.britain.features;
  const filteredFeatures = pickBy(prepareFeatures(featureKeys), ({ id }) => features?.includes(id));
  let index = 0;

  return mapValues(filteredFeatures, (feature) => {
    const featureIndex = index;
    index += 1;

    return {
      ...feature,
      index: featureIndex,
    };
  });
};

const getFeatureIllustration = ({
  id,
  locale,
  compact,
}: GetFeatureIllustrationParams): FeatureIllustration => {
  const imagePath = compact ? COMPACT_IMAGE_PATH : IMAGE_PATH;
  const image = kebabCase(id);

  return {
    src: `${imagePath}${image}-${toLower(locale)}.png`,
    fallbackSrc: `${imagePath}${image}-en.png`,
  };
};

export { getTags, getFeatures, getFeatureIllustration };
