import { Heading, Text, Stack } from '@carvertical/ui';
import Image from 'next/image';
import { motion, type MotionProps } from 'framer-motion';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { SocialYoutubeIconL } from '@carvertical/icons/react';
import type { BusinessTestimonial, Review } from 'types/content';
import Video from 'components/common/Video';
import styles from './TestimonialCard.module.scss';

type BusinessTestimonialType = {
  testimonial: BusinessTestimonial;
};

type InfluencerTestimonialType = {
  testimonial: Review;
};

type TestimonialProps = MotionProps & {
  className?: string;
} & (BusinessTestimonialType | InfluencerTestimonialType);

const isBusinessType = (
  testimonial: BusinessTestimonial | Review,
): testimonial is BusinessTestimonial => 'name' in (testimonial as BusinessTestimonial);

const VIDEO_ID_START_REGEX = /^(\w+)\?t=(\d+)$/;

const TestimonialCard = ({ className, testimonial, ...props }: TestimonialProps) => {
  const { t } = useTranslation(['home', 'common']);

  const businessType = isBusinessType(testimonial);

  const getVideo = () => {
    if (businessType) {
      return null;
    }

    const { youtubeId } = testimonial;
    const excerptId = `excerpt-${youtubeId}`;
    const [id, start] = youtubeId.match(VIDEO_ID_START_REGEX)?.slice(1) ?? [];

    const videoId = id || youtubeId;

    return {
      id: videoId,
      start: Number(start),
      excerptId,
    };
  };

  const config = {
    title: businessType ? testimonial.name : testimonial.author,
    subtitle: businessType
      ? testimonial.role
      : `${testimonial.subscribers} ${t('common:social.subscribersLabel')}`,
    quote: businessType ? testimonial.quote : testimonial.excerpt,
    photo: testimonial.photo.url,
    video: getVideo(),
  };

  const { photo, quote, subtitle, title, video } = config;

  return (
    <motion.div className={cx(styles.root, className)} key={title} {...props}>
      <Stack gap={2} className={styles.personWrapper}>
        <div className={styles.avatarWrapper}>
          <Image src={photo} alt={title ?? ''} className={styles.avatar} fill />
        </div>

        <Stack gap={0.5}>
          <Heading as="h4" variant="xs">
            {title}
          </Heading>

          <Text variant="s" textColor="darkSecondary">
            {subtitle}
          </Text>
        </Stack>
      </Stack>

      <Text variant="m" as="q" className={styles.quoteWrapper}>
        {quote}
      </Text>

      {!businessType && (
        <Video
          id={video?.id}
          start={video?.start}
          playedInModal
          // eslint-disable-next-line react/no-unstable-nested-components
          thumbnail={(play: () => void) => (
            <button
              type="button"
              className={styles.youtubeButton}
              aria-labelledby={video?.excerptId}
              onClick={play}
            >
              <Stack gap={1} type="horizontal" crossAxisAlign="center">
                <SocialYoutubeIconL />

                <Text variant="s+" textColor="inherited">
                  {' '}
                  {t('testimonialsSection.youtubeAction')}
                </Text>
              </Stack>
            </button>
          )}
        />
      )}
    </motion.div>
  );
};

export { TestimonialCard };
