import type { FeatureId } from 'modules/shared';

const IMAGE_PATH = '/images/features/';
const COMPACT_IMAGE_PATH = '/images/features/compact/';
const FEATURE_KEYS: FeatureId[] = [
  'photos',
  'damage',
  'stolenVehicleCheck',
  'mileage',
  'specifications',
  'emissionTaxes',
  'valuation',
  'maintenance',
  'usage',
  'titleCheck',
  'safety',
  'registrationRestrictions',
  'financialRestrictions',
  'naturalDisasters',
  'ownership',
  'emissions',
  'timeline',
  // TODO: Uncomment the following features when they are ready
  // 'ppsr',
  // 'fines',
];

export { FEATURE_KEYS, IMAGE_PATH, COMPACT_IMAGE_PATH };
