import { useTranslation } from 'next-i18next';
import { Hero } from 'components/landings/common/Hero';
import {
  SOLUTION_KEYS,
  INSURANCE_SOLUTION_KEYS,
  BUSINESS_ACTIONS,
  INSURANCE_ACTIONS,
  LEASING_ACTIONS,
  LEASING_SOLUTION_KEYS,
  HERO_SECTION_ID,
  API_ACTIONS,
  API_SOLUTION_KEYS,
} from '../constants';
import type { Config, ContentType } from '../types';
import { HeroIllustration } from './HeroIllustration';
import { SolutionsBlock } from './SolutionsBlock';
import styles from './BusinessPageHero.module.scss';

type BusinessPageHeroProps = ContentType;

const BUSINESS_CONFIG: Config = {
  titleKey: 'heroSection.title',
  subtitleKey: 'heroSection.description',
  solutions: SOLUTION_KEYS,
  actions: BUSINESS_ACTIONS,
};

const INSURANCE_CONFIG: Config = {
  titleKey: 'insuranceHeroSection.title',
  subtitleKey: 'insuranceHeroSection.description',
  solutions: INSURANCE_SOLUTION_KEYS,
  actions: INSURANCE_ACTIONS,
};

const LEASING_CONFIG: Config = {
  titleKey: 'leasingHeroSection.title',
  subtitleKey: 'leasingHeroSection.description',
  solutions: LEASING_SOLUTION_KEYS,
  actions: LEASING_ACTIONS,
};

const API_CONFIG: Config = {
  titleKey: 'apiHeroSection.title',
  subtitleKey: 'apiHeroSection.description',
  solutions: API_SOLUTION_KEYS,
  actions: API_ACTIONS,
};

const CONFIG = {
  business: BUSINESS_CONFIG,
  insurance: INSURANCE_CONFIG,
  leasing: LEASING_CONFIG,
  api: API_CONFIG,
};

const BusinessPageHero = ({ type }: BusinessPageHeroProps) => {
  const { t } = useTranslation(['business', 'common']);
  const { titleKey, subtitleKey, solutions, actions } = CONFIG[type];

  return (
    <Hero
      id={HERO_SECTION_ID}
      color="colorBlue100"
      leftContent={{
        identifier: null,
        title: t(titleKey),
        subtitle: t(subtitleKey),
        additionalContent: <SolutionsBlock solutions={solutions} actions={actions} />,
      }}
      rightContent={{
        className: styles.rightContent,
        illustration: <HeroIllustration type={type} size="m" />,
      }}
    />
  );
};

export { BusinessPageHero };
