import { useTranslation } from 'next-i18next';
import Page from 'components/common/Page';
import { CompactCallToActionSection } from 'modules/shared';
import { FeaturesTags } from './FeaturesTags';
import { FeaturesHero } from './FeaturesHero';
import { FeaturesList } from './FeaturesList';

const FeaturesPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false}>
      <FeaturesHero />

      {/* FIXME: https://carvertical.atlassian.net/browse/FE-12 */}
      <CompactCallToActionSection
        // @ts-expect-error TS(2322)
        title={t('featuresPage.cta.0.title')}
        // @ts-expect-error TS(2322)
        subtitle={t('featuresPage.cta.0.subtitle')}
        textClassName="text-left sm:max-lg:text-center sm:max-lg:max-w-96"
        className="md:pb-9 md:pt-0"
        trustBannerShown={false}
      />

      <FeaturesTags />

      <FeaturesList />

      {/* FIXME: https://carvertical.atlassian.net/browse/FE-12 */}
      <CompactCallToActionSection
        // @ts-expect-error TS(2322)
        title={t('featuresPage.cta.2.title')}
        // @ts-expect-error TS(2322)
        subtitle={t('featuresPage.cta.2.subtitle')}
        textClassName="text-left sm:max-lg:text-center sm:max-lg:max-w-96"
        className="mb-0 md:mt-9 md:pt-0"
        trustBannerShown={false}
      />
    </Page>
  );
};

export { FeaturesPage };
