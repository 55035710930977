import { useState } from 'react';
import cx from 'classnames';
import { Button, Container, Grid, GridItem, Heading, Stack, Text } from '@carvertical/ui';
import Image from 'next/image';
import Anchor from 'components/common/Anchor';
import styles from './BenefitBlock.module.scss';

type BenefitBlockProps = {
  title: string;
  description: string;
  imageSrc: string;
  reverse?: boolean;
  fallbackImageSrc?: string;
  ctaText: string;
  ctaAnchorId: string;
};

const BenefitBlock = ({
  ctaAnchorId,
  ctaText,
  description,
  fallbackImageSrc,
  imageSrc,
  reverse = false,
  title,
}: BenefitBlockProps) => {
  const [actualImageSrc, setActualImageSrc] = useState(imageSrc);

  return (
    <Grid
      columnCount={{
        mobileUp: 1,
        tabletLandscapeUp: 12,
      }}
      columnGap={4}
      rowGap={3}
      className={styles.root}
      as={Container}
    >
      <GridItem
        span={{
          mobileUp: 1,
          tabletLandscapeUp: 6,
        }}
        className={cx(styles.content, reverse && styles.reverse)}
      >
        <Stack
          gap={{
            mobileUp: 2,
            tabletLandscapeUp: 4,
          }}
        >
          <Heading variant="l" textColor="light" as="h2" className={styles.title}>
            {title}
          </Heading>

          <Text variant="m" textColor="light" className={styles.description}>
            {description}
          </Text>

          <div className={styles.buttonWrapper}>
            <Button
              variant="outlined"
              size="l"
              color="light"
              className={styles.button}
              as={Anchor}
              // @ts-expect-error TS(2322)
              scroll={false}
              id={ctaAnchorId}
            >
              {ctaText}
            </Button>
          </div>
        </Stack>
      </GridItem>

      <GridItem
        span={{
          mobileUp: 1,
          tabletLandscapeUp: 6,
        }}
      >
        <div className={styles.imageWrapper}>
          <Image
            fill
            src={actualImageSrc}
            alt=""
            className={styles.image}
            onError={() => fallbackImageSrc && setActualImageSrc(fallbackImageSrc)}
          />
        </div>
      </GridItem>
    </Grid>
  );
};

export { BenefitBlock };
export type { BenefitBlockProps };
