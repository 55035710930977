import { Container } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';
import HiddenCarProblemsIllustration from '../../assets/hidden-car-problems.svg';

// FIXME: https://carvertical.atlassian.net/browse/FE-12
// @ts-expect-error TS(2322)
const DESCRIPTION_KEYS: ParseKeys[] = ['featuresPage.description.0', 'featuresPage.description.1'];

const FeaturesHero = () => {
  const { t } = useTranslation();

  return (
    <section className="pb-8 pt-4 md:pb-9 md:pt-10">
      <Container className="grid grid-cols-1 gap-4 md:grid-cols-[1.2fr_1fr] md:gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-h-l">
            <Trans t={t} i18nKey="featuresPage.title" components={{ 1: <br /> }} />
          </h1>

          <div className="flex flex-col gap-3 md:max-w-72">
            {DESCRIPTION_KEYS.map((key) => (
              <p className="text-m md:text-l" key={key}>
                <Trans
                  // @ts-expect-error(2339)
                  t={t}
                  i18nKey={key}
                  components={{ 1: <strong className="text-m+ md:text-l+" /> }}
                />
              </p>
            ))}
          </div>
        </div>

        <HiddenCarProblemsIllustration
          className="mx-auto w-full max-w-48 max-md:mt-6 md:max-w-64"
          original
        />
      </Container>
    </section>
  );
};

export { FeaturesHero };
