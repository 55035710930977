import Page from 'components/common/Page';
import { Header } from 'modules/shared';
import { TrustBar } from '../TrustBar';
import { CountryCampaignHero } from './CountryCampaignHero';

const CountryCampaignPage = () => (
  <Page breadcrumbs={false} header={<Header logoOnly logoInteractive={false} />}>
    <CountryCampaignHero />

    <TrustBar />
  </Page>
);

export { CountryCampaignPage };
