import { useCallback, useEffect } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import { useGeoData, type FeatureId } from 'modules/shared';
import { getFeatures, getTags } from '../utils';
import {
  useFeaturesSectionList,
  useHighlightedFeatureIndex,
  useFeaturesSectionActions,
} from './useFeaturesSectionStore';

const FEATURE_KEYS: FeatureId[] = [
  'photos',
  'damage',
  'stolenVehicleCheck',
  'mileage',
  'specifications',
  'emissionTaxes',
  'valuation',
  'usage',
  'titleCheck',
  'safety',
  'registrationRestrictions',
  'financialRestrictions',
  'naturalDisasters',
];

const useFeaturesSection = () => {
  const features = useFeaturesSectionList();
  const highlightedFeatureIndex = useHighlightedFeatureIndex();
  const geoData = useGeoData();
  const { market } = useRouteData();
  const { highlight, addFeatures } = useFeaturesSectionActions();
  const tags = getTags(features);

  useEffect(() => {
    addFeatures(
      getFeatures({ market, countryCode: geoData?.countryCode, featureKeys: FEATURE_KEYS }),
    );
  }, [geoData, market, addFeatures]);

  const highlightFeature = useCallback(
    (index: number) => {
      highlight(index);
    },
    [highlight],
  );

  return { features, tags, highlightedFeature: highlightedFeatureIndex, highlightFeature };
};

export { useFeaturesSection };
