import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { emailSchema } from 'modules/shared/schemas';
import { SIX_DIGITS_REGEXP } from 'modules/shared';

type EmailPasswordFormSchemaProps = {
  passwordOmitted?: boolean;
  anyPasswordLengthAllowed?: boolean;
};

const PASSWORD_MIN_LENGTH = 10;

const requiredFieldSchema = z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required });

const passwordFieldSchema = requiredFieldSchema.min(PASSWORD_MIN_LENGTH, {
  message: ERROR_TRANSLATION_KEYS.password,
});

const getEmailPasswordFormSchema = ({
  passwordOmitted,
  anyPasswordLengthAllowed,
}: EmailPasswordFormSchemaProps) =>
  z.object({
    email: emailSchema,
    ...(!passwordOmitted && {
      password: anyPasswordLengthAllowed ? requiredFieldSchema : passwordFieldSchema,
    }),
  });

const resetPasswordFormSchema = z
  .object({
    password: passwordFieldSchema,
    repeatedPassword: passwordFieldSchema,
  })
  .refine(({ password, repeatedPassword }) => password === repeatedPassword, {
    message: ERROR_TRANSLATION_KEYS.passwordNotEqual,
    path: ['repeatedPassword'],
  });

const newUserFormSchema = z.object({
  termsAccepted: z.literal(true).optional(),
  newsletterAccepted: z.boolean().optional(),
});

const loginCodeFormSchema = z.object({
  code: z.string().regex(SIX_DIGITS_REGEXP),
});

type EmailPasswordFormFields = z.infer<ReturnType<typeof getEmailPasswordFormSchema>>;
type ResetPasswordFormFields = z.infer<typeof resetPasswordFormSchema>;
type NewUserFormFields = z.infer<typeof newUserFormSchema>;
type LoginCodeFormFields = z.infer<typeof loginCodeFormSchema>;

export {
  getEmailPasswordFormSchema,
  resetPasswordFormSchema,
  newUserFormSchema,
  loginCodeFormSchema,
};
export type {
  EmailPasswordFormFields,
  ResetPasswordFormFields,
  NewUserFormFields,
  LoginCodeFormFields,
};
