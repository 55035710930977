import { create } from 'zustand';
import type { PartialFeaturesRecord } from '../types';

type FeaturesSectionStore = {
  features: PartialFeaturesRecord;
  highlightedFeatureIndex: number;
  actions: {
    highlight: (index: number) => void;
    addFeatures: (features: PartialFeaturesRecord) => void;
  };
};

const useFeaturesSectionStore = create<FeaturesSectionStore>((set) => ({
  features: {},
  highlightedFeatureIndex: 0,
  actions: {
    highlight: (index: number) => set({ highlightedFeatureIndex: index }),
    addFeatures: (features) => set({ features }),
  },
}));

const useFeaturesSectionList = () => useFeaturesSectionStore((store) => store.features);
const useHighlightedFeatureIndex = () =>
  useFeaturesSectionStore((store) => store.highlightedFeatureIndex);
const useFeaturesSectionActions = () => useFeaturesSectionStore((store) => store.actions);

export { useFeaturesSectionList, useHighlightedFeatureIndex, useFeaturesSectionActions };
