import { Tag, TagGroup, type TagGroupProps, Container } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { debounce, type DebouncedFunc } from 'lodash';
import { isTranslationKeyValid } from 'modules/shared';
import { useFeatures } from '../../hooks';

const TAGS_CONTAINER_OFFSET = 16;
const SCROLL_DEBOUNCE_TIME = 100;

const FeaturesTags = () => {
  const { t } = useTranslation();
  const { tags, highlightedFeature } = useFeatures();
  const tagsContainerRef = useRef<HTMLDivElement>(null);

  useScrollToTag(tagsContainerRef);

  const onTagChange: TagGroupProps['onChange'] = (keys) => {
    if (typeof keys === 'object') {
      Object.values(keys).forEach((key) => {
        if (!key && typeof key !== 'number') {
          return;
        }

        const featuredArticle = document.getElementById(key);

        if (featuredArticle) {
          featuredArticle.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      });
    }
  };

  if (!tags.length) {
    return null;
  }

  return (
    <div className="sticky top-8 z-10">
      <Container className="bg-white max-md:px-0">
        <TagGroup
          aria-label={t('features.informationTitle')}
          listClassName="hideScrollbar scroll-p-0 scroll-smooth snap-x snap-mandatory max-md:px-2 flex flex-nowrap md:flex-wrap md:justify-center py-2 md:mb-6 overflow-x-auto"
          selectionMode="single"
          selectedKeys={[highlightedFeature]}
          onChange={onTagChange}
          ref={tagsContainerRef}
        >
          {tags.map(({ id, index }) => {
            const titleKey = isTranslationKeyValid(`features.${id}.alternativeTitle`)
              ? `features.${id}.alternativeTitle`
              : `features.${id}.title`;
            const titleKeyValid = isTranslationKeyValid(titleKey);

            if (!titleKeyValid) {
              return null;
            }

            return (
              <Tag key={index} id={index} size="l" className="flex-shrink-0">
                {t(titleKey)}
              </Tag>
            );
          })}
        </TagGroup>
      </Container>
    </div>
  );
};

function useScrollToTag(tagsContainerRef: React.MutableRefObject<HTMLDivElement | null>) {
  const { highlightedFeature } = useFeatures();

  useEffect(() => {
    const tagsContainer = tagsContainerRef.current;
    let scrollToTag: DebouncedFunc<() => void> | undefined;

    if (tagsContainer && tagsContainer.scrollWidth > tagsContainer.clientWidth) {
      const highlightedTag = tagsContainer.querySelector(`div[data-key="${highlightedFeature}"]`);

      if (highlightedTag instanceof HTMLElement) {
        scrollToTag = debounce(() => {
          tagsContainer.scrollTo({
            left: highlightedTag.offsetLeft - TAGS_CONTAINER_OFFSET,
            behavior: 'smooth',
          });
        }, SCROLL_DEBOUNCE_TIME);

        scrollToTag();
      }
    }

    return () => scrollToTag?.cancel();
  }, [tagsContainerRef, highlightedFeature]);
}

export { FeaturesTags };
