import cx from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import {
  Container,
  Grid,
  Heading,
  Stack,
  Text,
  Button,
  Badge,
  GridItem,
  Radio,
} from '@carvertical/ui';
import { motion } from 'framer-motion';
import { filter, isEmpty, max } from 'lodash';
import { useEffect, useState } from 'react';
import { PlusCircleIconL } from '@carvertical/icons/react';
import { usePricing } from 'context/PricingProvider';
import Loading from 'components/common/Loading';
import { BuyReportButton } from 'components/common/BuyReportButton';
import Anchor from 'components/common/Anchor';
import Price from 'components/common/Price';
import { Section } from 'components/landings/common/Section';
import type { $TSFixMe } from 'types';
import type { PackageId } from 'types/pricing';
import { CONTACT_SECTION_ID, PRICING_SECTION_ID } from '../constants';
import styles from './PricingSection.module.scss';

type PricingSectionProps = {
  preventMarketSuggestion?: boolean;
};

const CARD_VARIANTS = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.85,
  },
};

const PricingSection = ({ preventMarketSuggestion = false }: PricingSectionProps) => {
  const { t } = useTranslation(['business', 'pricing', 'common']);
  const [activePricingSubId, setActivePricingSubId] = useState<PackageId | null>(null);

  const { fetching, ...smallPricing } = usePricing('bizsm');
  // const activePricingSub = usePricing(activePricingSubId);
  const smallPricingSub = usePricing('bizsmsub');
  const mediumPricingSub = usePricing('bizmdsub');
  const mediumPricing = usePricing('bizmd');
  const largePricing = usePricing('bizlg');

  const subscriptionPackageShown = !isEmpty(smallPricingSub) || !isEmpty(mediumPricingSub);
  const subscriptionPackages = filter(
    [smallPricingSub, mediumPricingSub],
    (pricing) => !isEmpty(pricing),
  );
  const packages = filter(
    [smallPricing, mediumPricing, largePricing],
    (pricing) => !isEmpty(pricing),
  );

  useEffect(() => {
    if (subscriptionPackages.length > 0 && !activePricingSubId) {
      setActivePricingSubId(subscriptionPackages[0].id);
    }
  }, [subscriptionPackages, activePricingSubId]);

  const activePricingSub = subscriptionPackages.find(({ id }) => id === activePricingSubId);
  const maxDiscount = max(packages.map((pkg) => (pkg?.discount as number) || 0));

  const renderPricingBlock = ({
    pricing: { $, ...pricing },
    isSubscription,
    className,
  }: {
    pricing: $TSFixMe;
    isSubscription?: boolean;
    className?: string;
  }) => (
    <Stack
      as={motion.li}
      id={pricing.id}
      gap={4}
      key={isSubscription ? undefined : pricing.id}
      className={cx(styles.pack, isSubscription && styles.highlighted, className)}
      crossAxisAlign="stretch"
      // @ts-expect-error TS2332
      variants={CARD_VARIANTS}
      initial="hidden"
      animate="visible"
    >
      {isSubscription && (
        <div className={styles.banner}>
          <Text variant="s+" textColor="light">
            {t('common:pricing.saveWithSubscription')}
          </Text>
        </div>
      )}

      <Stack
        gap={{
          mobileUp: 3,
          tabletPortraitUp: 4,
        }}
        crossAxisAlign="center"
      >
        {isSubscription && (
          <Text as="h3" variant="l+" align="center">
            {t('common:pricing.subscriptionLabel')}
          </Text>
        )}

        {!isSubscription && (
          <Text as="h3" variant="l+" align="center">
            <Stack type="horizontal" crossAxisAlign="center">
              {t('business:pricing.reportsLabel', {
                count: pricing.reportCount,
              })}
            </Stack>
          </Text>
        )}

        <Stack gap={0} crossAxisAlign="center">
          <Stack type="horizontal" gap={1}>
            <Heading as="h4" variant="l" className={styles.title}>
              <Price>{$(pricing.net.unitPrice)}</Price>
            </Heading>
          </Stack>

          <Text variant="m">{t('common:pricing.unitPriceLabelShort')}</Text>
        </Stack>

        {isSubscription && (
          <div className="flex w-full min-w-20 max-w-32 flex-col gap-2 border border-x-0 border-solid border-y-grey-400 py-3">
            {subscriptionPackages.map(({ id, reportCount }) => (
              <Radio
                className="w-full"
                key={id}
                name="subscriptionType"
                checked={activePricingSubId === id}
                label={
                  <Text className="align-center flex flex-wrap gap-0.5" variant="m+">
                    {t('business:pricing.reportsLabel', {
                      count: reportCount,
                    })}

                    <Text as="span" variant="s">
                      / {t('common:general.monthLabel')}
                    </Text>
                  </Text>
                }
                onChange={() => setActivePricingSubId(id)}
              />
            ))}
          </div>
        )}
      </Stack>

      <Stack crossAxisAlign="center" className={styles.actions}>
        <Text variant="m" textColor="darkSecondary">
          {t('common:pricing.discountLabel', {
            percentage: pricing.discount,
          })}
        </Text>

        <BuyReportButton
          fullWidth
          size="l"
          preselectedPackageId={pricing.id}
          className={styles.button}
          subscription={isSubscription}
          preventMarketSuggestion={preventMarketSuggestion}
        >
          {isSubscription ? (
            t('common:general.subscribeActionFormal')
          ) : (
            <Trans
              i18nKey="pricing.buyAction"
              parent="span"
              t={t}
              values={{ price: $(pricing.net.price) }}
              components={{ 1: <Price /> }}
            />
          )}
        </BuyReportButton>
      </Stack>
    </Stack>
  );

  return (
    <Section id={PRICING_SECTION_ID}>
      <Container>
        <Stack gap={{ mobileUp: 4, tabletLandscapeUp: 3 }} crossAxisAlign="stretch">
          <Stack
            gap={{
              mobileUp: subscriptionPackageShown ? 8 : 5,
              tabletLandscapeUp: 8,
            }}
            crossAxisAlign="stretch"
          >
            <Stack gap={3} crossAxisAlign="center">
              <Stack crossAxisAlign="center">
                <Heading as="h2" variant="l" align="center">
                  {t('pricing.title')}
                </Heading>

                <Text variant="l" align="center">
                  {t('pricing.description', {
                    percentage: maxDiscount,
                  })}
                </Text>
              </Stack>
            </Stack>

            {fetching ? (
              <Loading className={styles.loader} />
            ) : (
              <Grid
                columnCount={{
                  mobileUp: 1,
                  tabletPortraitUp: 2,
                  desktopUp: 4,
                }}
                rowGap={{ mobileUp: 4, desktopUp: 2, desktopBigUp: 4 }}
                columnGap={{
                  mobileUp: 4,
                  desktopUp: 2,
                  desktopBigUp: 4,
                }}
                className="unstyledList"
                as="ul"
              >
                {!isEmpty(activePricingSub) &&
                  subscriptionPackageShown &&
                  renderPricingBlock({
                    pricing: activePricingSub,
                    isSubscription: true,
                    className: 'lg:row-span-2',
                  })}

                {packages.map((pricing) => renderPricingBlock({ pricing }))}

                <GridItem
                  span={{
                    mobileUp: 1,
                    tabletPortraitUp: subscriptionPackageShown ? 2 : 1,
                    desktopUp: subscriptionPackageShown ? 4 : 1,
                  }}
                  className={cx(
                    styles.customOfferWrapper,
                    subscriptionPackageShown && 'lg:col-span-3',
                  )}
                  as={motion.li}
                  // @ts-expect-error TS2332
                  variants={CARD_VARIANTS}
                  initial="hidden"
                  animate="visible"
                >
                  <div className={cx(styles.customOffer, styles.blue)}>
                    <div className={styles.headingWrapper}>
                      <PlusCircleIconL className="stroke-white stroke-1 text-white" />

                      <Heading as="h4" variant="s" textColor="light" className={styles.heading}>
                        {t('pricing.buyMoreLabel')} {t('pricing.getOfferLabel')}
                      </Heading>
                    </div>

                    <Anchor id={CONTACT_SECTION_ID} scroll={false} className={styles.button}>
                      <Button fullWidth size="l" variant="yellow">
                        {t('common:general.contactUsActionFormal')}
                      </Button>
                    </Anchor>
                  </div>
                </GridItem>
              </Grid>
            )}
          </Stack>

          <Stack
            gap={2}
            crossAxisAlign="center"
            mainAxisAlign="spaceBetween"
            className={styles.disclaimer}
          >
            <Badge variant="grey" size="s">
              {t('pricing:credits.disclaimer')}
            </Badge>
          </Stack>
        </Stack>
      </Container>
    </Section>
  );
};

export { PricingSection };
