import { theme } from '@carvertical/ui';
import Image from 'next/image';
import cx from 'classnames';
import { useMedia } from 'react-use';
import { MILES_MARKETS, TOTAL_MARKETS } from 'constants/markets';
import { StarRating } from 'components/common/ui/StarRating';
import avatarsUrl from 'assets/images/trustpilot/trustpilot-avatars-clean.png';
import { createMediaQuery } from 'utils/styles';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import { Ticker } from 'components/landings/common/Ticker';
import type { Market } from 'types/market';
import type { ContentType } from '../types';
import LeasingImage2 from '../assets/images/leasing-hero-image-2.jpg';
import LeasingImage3 from '../assets/images/leasing-hero-image-3.jpg';
import LeasingImage4 from '../assets/images/leasing-hero-image-4.jpg';
import BusinessImage1 from '../assets/images/business-hero-image-1.jpg';
import BusinessImage2 from '../assets/images/business-hero-image-2.jpg';
import BusinessImage3 from '../assets/images/business-hero-image-3.jpg';
import BusinessImage4 from '../assets/images/business-hero-image-4.jpg';
import BusinessImage5 from '../assets/images/business-hero-image-5.jpg';
import InsuranceImage1 from '../assets/images/insurance-hero-image-1.jpg';
import InsuranceImage2 from '../assets/images/insurance-hero-image-2.jpg';
import InsuranceImage3 from '../assets/images/insurance-hero-image-3.jpg';
import InsuranceImage4 from '../assets/images/insurance-hero-image-4.jpg';
import PlBusinessImage1 from '../assets/images/pl-business-hero-image-1.jpg';
import PlBusinessImage2 from '../assets/images/pl-business-hero-image-2.jpg';
import PlBusinessImage3 from '../assets/images/pl-business-hero-image-3.jpg';
import PlBusinessImage4 from '../assets/images/pl-business-hero-image-4.jpg';
import FrBusinessImage1 from '../assets/images/fr-business-hero-image-1.jpg';
import FrBusinessImage2 from '../assets/images/fr-business-hero-image-2.jpg';
import FrBusinessImage3 from '../assets/images/fr-business-hero-image-3.jpg';
import FrBusinessImage4 from '../assets/images/fr-business-hero-image-4.jpg';
import ItBusinessImage1 from '../assets/images/it-business-hero-image-1.jpg';
import ItBusinessImage2 from '../assets/images/it-business-hero-image-2.jpg';
import ItBusinessImage3 from '../assets/images/it-business-hero-image-3.jpg';
import ItBusinessImage4 from '../assets/images/it-business-hero-image-4.jpg';
import ApiImage1 from '../assets/images/api-hero-image-1.jpg';
import ApiImage2 from '../assets/images/api-hero-image-2.jpg';
import ApiImage3 from '../assets/images/api-hero-image-3.jpg';
import LeasingImage1 from '../assets/images/leasing-hero-image-1.jpg';
import { TickerItem, type TickerItemProps } from './TickerItem';
import { IsoCertifiedIllustration } from './IsoCertifiedIllustration';
import styles from './HeroIllustration.module.scss';

type HeroIllustrationProps = { size?: 'm' | 'l' | 'xl' } & ContentType;

type TickerConfig = Record<ContentType['type'], TickerItemProps[]>;
type TickerItems = { firstTicker: TickerItemProps[]; secondTicker: TickerItemProps[] };

const DATA_SOURCES_ITEM: TickerItemProps = {
  descriptionKey: 'figuresSection.dataSources',
  title: '900+',
  color: 'black',
  id: 'dataSources',
};

const USERS_PER_MONTH_ITEM: TickerItemProps = {
  descriptionKey: 'figuresSection.usersPerMonth',
  title: '1 800 000+',
  color: 'white',
  id: 'usersPerMonth',
};

const BUSINESS_PARTNERS_ITEM: TickerItemProps = {
  descriptionKey: 'figuresSection.businessPartners',
  title: '2 200+',
  color: 'white',
  id: 'businessPartners',
};

const AVERAGE_DAMAGED_CARS_ITEM = (dark?: boolean): TickerItemProps => ({
  descriptionKey: 'figuresSection.averageDamagedCars',
  title: '42%',
  color: dark ? 'blue' : 'white',
  id: 'averageDamagedCars',
});

const EMPLOYEES_ITEM = (dark?: boolean): TickerItemProps => ({
  descriptionKey: 'figuresSection.employees',
  title: '160+',
  color: dark ? 'black' : 'white',
  id: 'employees',
});

const TRUST_LABEL_ITEM: TickerItemProps = {
  descriptionKey: 'landing.trustLabel',
  title: <Image src={avatarsUrl} alt="Trustpilot clients" width={87} height={32} />,
  color: 'blue',
  footer: <StarRating color="white" rating={5} size="s" className={styles.rating} />,
  id: 'trustLabel',
};

const MARKETS_ITEM: TickerItemProps = {
  descriptionKey: 'figuresSection.markets',
  title: `${TOTAL_MARKETS}`,
  color: 'white',
  id: 'markets',
};

const MEDIA_PUBLICATIONS_ITEM = (dark?: boolean): TickerItemProps => ({
  descriptionKey: 'figuresSection.mediaPublications',
  title: '1000+',
  color: dark ? 'black' : 'blue',
  id: 'mediaPublications',
});

const GLOBAL_AVERAGE_DAMAGE_ITEM: TickerItemProps = {
  descriptionKey: 'figuresSection.globalAverageDamage',
  title: '5 625 €',
  color: 'blue',
  id: 'globalAverageDamage',
};

const GLOBAL_AVERAGE_ODOMETER_ITEM = (milesUnit?: boolean): TickerItemProps => ({
  descriptionKey: 'figuresSection.globalAverageOdometer',
  title: `77, 000 ${milesUnit ? 'mi' : 'km'}`,
  color: 'white',
  id: 'globalAverageOdometer',
});

const ISO_DESCRIPTION_ITEM: TickerItemProps = {
  descriptionKey: 'landing.isoDescription',
  title: <IsoCertifiedIllustration />,
  color: 'black',
  id: 'isoDescription',
};

const BUSINESS_TICKER_1_ITEMS: TickerItemProps[] = [
  DATA_SOURCES_ITEM,
  { image: BusinessImage1, id: 'businessImage1' },
  USERS_PER_MONTH_ITEM,
  { image: BusinessImage2, id: 'businessImage2' },
  BUSINESS_PARTNERS_ITEM,
  AVERAGE_DAMAGED_CARS_ITEM(true),
  { image: BusinessImage3, id: 'businessImage3' },
  EMPLOYEES_ITEM(),
];

const BUSINESS_TICKER_2_ITEMS: TickerItemProps[] = [
  { image: BusinessImage4, id: 'businessImage4' },
  TRUST_LABEL_ITEM,
  { image: BusinessImage5, id: 'businessImage5' },
  MARKETS_ITEM,
  MEDIA_PUBLICATIONS_ITEM(),
];

const PL_BUSINESS_TICKER_1_ITEMS: TickerItemProps[] = [
  DATA_SOURCES_ITEM,
  { image: PlBusinessImage1, id: 'PlBusinessImage1' },
  USERS_PER_MONTH_ITEM,
  { image: PlBusinessImage2, id: 'PlBusinessImage2' },
  BUSINESS_PARTNERS_ITEM,
  AVERAGE_DAMAGED_CARS_ITEM(true),
  { image: BusinessImage3, id: 'businessImage3' },
  EMPLOYEES_ITEM(),
];

const PL_BUSINESS_TICKER_2_ITEMS: TickerItemProps[] = [
  { image: PlBusinessImage3, id: 'PlBusinessImage3' },
  TRUST_LABEL_ITEM,
  { image: PlBusinessImage4, id: 'PlBusinessImage4' },
  MARKETS_ITEM,
  MEDIA_PUBLICATIONS_ITEM(),
];

const FR_BUSINESS_TICKER_1_ITEMS: TickerItemProps[] = [
  DATA_SOURCES_ITEM,
  { image: FrBusinessImage1, id: 'FrBusinessImage1' },
  USERS_PER_MONTH_ITEM,
  { image: FrBusinessImage2, id: 'FrBusinessImage2' },
  BUSINESS_PARTNERS_ITEM,
  AVERAGE_DAMAGED_CARS_ITEM(true),
  { image: BusinessImage3, id: 'businessImage3' },
  EMPLOYEES_ITEM(),
];

const FR_BUSINESS_TICKER_2_ITEMS: TickerItemProps[] = [
  { image: FrBusinessImage3, id: 'FrBusinessImage3' },
  TRUST_LABEL_ITEM,
  { image: FrBusinessImage4, id: 'FrBusinessImage4' },
  MARKETS_ITEM,
  MEDIA_PUBLICATIONS_ITEM(true),
];

const IT_BUSINESS_TICKER_1_ITEMS: TickerItemProps[] = [
  DATA_SOURCES_ITEM,
  { image: ItBusinessImage1, id: 'ItBusinessImage1' },
  USERS_PER_MONTH_ITEM,
  { image: ItBusinessImage2, id: 'ItBusinessImage2' },
  BUSINESS_PARTNERS_ITEM,
  AVERAGE_DAMAGED_CARS_ITEM(true),
  { image: BusinessImage3, id: 'businessImage3' },
  EMPLOYEES_ITEM(),
];

const IT_BUSINESS_TICKER_2_ITEMS: TickerItemProps[] = [
  { image: ItBusinessImage3, id: 'ItBusinessImage3' },
  TRUST_LABEL_ITEM,
  { image: ItBusinessImage4, id: 'ItBusinessImage4' },
  MARKETS_ITEM,
  MEDIA_PUBLICATIONS_ITEM(true),
];

const INSURANCE_TICKER_1_ITEMS: ({ milesUnit }: { milesUnit: boolean }) => TickerItemProps[] = ({
  milesUnit,
}) => [
  DATA_SOURCES_ITEM,
  { image: InsuranceImage1, id: 'insuranceImage1' },
  AVERAGE_DAMAGED_CARS_ITEM(),
  { image: InsuranceImage2, id: 'insuranceImage2' },
  GLOBAL_AVERAGE_DAMAGE_ITEM,
  GLOBAL_AVERAGE_ODOMETER_ITEM(milesUnit),
];

const INSURANCE_TICKER_2_ITEMS: TickerItemProps[] = [
  BUSINESS_PARTNERS_ITEM,
  { image: InsuranceImage3, id: 'insuranceImage3' },
  ISO_DESCRIPTION_ITEM,
  TRUST_LABEL_ITEM,
  { image: InsuranceImage4, id: 'insuranceImage4' },
  MARKETS_ITEM,
  EMPLOYEES_ITEM(),
];

const LEASING_TICKER_1_ITEMS: ({ milesUnit }: { milesUnit: boolean }) => TickerItemProps[] = ({
  milesUnit,
}) => [
  DATA_SOURCES_ITEM,
  AVERAGE_DAMAGED_CARS_ITEM(),
  { image: LeasingImage1, id: 'leasingImage1' },
  GLOBAL_AVERAGE_DAMAGE_ITEM,
  { image: LeasingImage2, id: 'leasingImage2' },
  GLOBAL_AVERAGE_ODOMETER_ITEM(milesUnit),
];

const LEASING_TICKER_2_ITEMS: TickerItemProps[] = [
  BUSINESS_PARTNERS_ITEM,
  { image: LeasingImage3, id: 'leasingImage3' },
  ISO_DESCRIPTION_ITEM,
  TRUST_LABEL_ITEM,
  { image: LeasingImage4, id: 'leasingImage4' },
  MARKETS_ITEM,
  EMPLOYEES_ITEM(),
];

const API_TICKER_1_ITEMS: TickerItemProps[] = [
  DATA_SOURCES_ITEM,
  { image: BusinessImage1, id: 'businessImage1' },
  USERS_PER_MONTH_ITEM,
  { image: ApiImage1, id: 'apiImage1' },
  BUSINESS_PARTNERS_ITEM,
  AVERAGE_DAMAGED_CARS_ITEM(true),
  { image: BusinessImage3, id: 'businessImage3' },
  EMPLOYEES_ITEM(),
];

const API_TICKER_2_ITEMS: TickerItemProps[] = [
  { image: ApiImage2, id: 'apiImage2' },
  ISO_DESCRIPTION_ITEM,
  TRUST_LABEL_ITEM,
  { image: ApiImage3, id: 'apiImage3' },
  MARKETS_ITEM,
  MEDIA_PUBLICATIONS_ITEM(true),
];

const HeroIllustration = ({ type, size = 'm' }: HeroIllustrationProps) => {
  const { market } = useRouteData();

  const hasMilesUnit = isExistingMarket({ markets: MILES_MARKETS, market });
  const businessTickerItems = getBusinessTickerItems(market);

  const firstTickerConfig: TickerConfig = {
    business: businessTickerItems.firstTicker,
    insurance: INSURANCE_TICKER_1_ITEMS({ milesUnit: hasMilesUnit }),
    leasing: LEASING_TICKER_1_ITEMS({ milesUnit: hasMilesUnit }),
    api: API_TICKER_1_ITEMS,
  };

  const secondTickerConfig: TickerConfig = {
    business: businessTickerItems.secondTicker,
    insurance: INSURANCE_TICKER_2_ITEMS,
    leasing: LEASING_TICKER_2_ITEMS,
    api: API_TICKER_2_ITEMS,
  };

  const isDesktop = useMedia(createMediaQuery(theme.breakpointDesktop), false);

  const renderCards = (items: TickerItemProps[]) =>
    items.map((item) => <TickerItem key={item.id} {...item} />);

  return (
    <div className={cx(styles.root, styles[size])}>
      <Ticker
        variant={isDesktop ? 'vertical' : 'horizontal'}
        direction={-1}
        duration={isDesktop ? 50 : 60}
      >
        {renderCards(firstTickerConfig[type])}
      </Ticker>

      <Ticker
        variant={isDesktop ? 'vertical' : 'horizontal'}
        direction={-1}
        duration={isDesktop ? 70 : 80}
      >
        {renderCards(secondTickerConfig[type])}
      </Ticker>
    </div>
  );
};

function getBusinessTickerItems(market: Market): TickerItems {
  const polishMarket = isExistingMarket({ markets: ['poland'], market });
  const frenchMarket = isExistingMarket({ markets: ['france'], market });
  const italianMarket = isExistingMarket({ markets: ['italy'], market });

  if (polishMarket) {
    return { firstTicker: PL_BUSINESS_TICKER_1_ITEMS, secondTicker: PL_BUSINESS_TICKER_2_ITEMS };
  }

  if (frenchMarket) {
    return { firstTicker: FR_BUSINESS_TICKER_1_ITEMS, secondTicker: FR_BUSINESS_TICKER_2_ITEMS };
  }

  if (italianMarket) {
    return { firstTicker: IT_BUSINESS_TICKER_1_ITEMS, secondTicker: IT_BUSINESS_TICKER_2_ITEMS };
  }

  return { firstTicker: BUSINESS_TICKER_1_ITEMS, secondTicker: BUSINESS_TICKER_2_ITEMS };
}

export { HeroIllustration };
