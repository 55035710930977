import { useCallback, useEffect } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import { useGeoData, type FeatureId } from 'modules/shared';
import type { OptionalMarketIdRecord } from 'types/market';
import { getFeatures, getTags } from '../utils';
import {
  useFeaturesList,
  useHighlightedFeatureIndex,
  useFeaturesActions,
} from './useFeaturesStore';

const FEATURE_KEYS: OptionalMarketIdRecord<FeatureId[]> = {
  britain: [
    'damage',
    'photos',
    'valuation',
    'mileage',
    'ownership',
    'timeline',
    'stolenVehicleCheck',
    'financialRestrictions',
    'safety',
    'emissions',
    'naturalDisasters',
    'maintenance',
    'specifications',
  ],
  france: [
    'damage',
    'photos',
    'mileage',
    'valuation',
    'timeline',
    'stolenVehicleCheck',
    'safety',
    'emissions',
    'naturalDisasters',
    'specifications',
  ],
  italy: [
    'damage',
    'photos',
    'valuation',
    'mileage',
    'ownership',
    'timeline',
    'stolenVehicleCheck',
    'safety',
    'emissions',
    'naturalDisasters',
    'specifications',
  ],
  poland: [
    'damage',
    'photos',
    'mileage',
    'valuation',
    'timeline',
    'safety',
    'emissions',
    'naturalDisasters',
    'specifications',
  ],
  romania: [
    'damage',
    'photos',
    'mileage',
    'usage',
    'valuation',
    'timeline',
    'safety',
    'emissions',
    'naturalDisasters',
    'specifications',
  ],
};

const FEATURE_SLOT_INDEXES: OptionalMarketIdRecord<number[]> = {
  britain: [3, 5, 8, 10],
  france: [2, 5, 6],
  italy: [3, 5, 7],
  poland: [2, 4],
  romania: [2, 5],
};

const useFeatures = () => {
  const features = useFeaturesList();
  const highlightedFeatureIndex = useHighlightedFeatureIndex();
  const { highlight, addFeatures } = useFeaturesActions();
  const geoData = useGeoData();
  const { market } = useRouteData();
  const tags = getTags(features);
  const featureSlotsIndexes = FEATURE_SLOT_INDEXES[market.id] ?? null;

  useEffect(() => {
    addFeatures(
      getFeatures({
        market,
        countryCode: geoData?.countryCode,
        featureKeys: FEATURE_KEYS[market.id] ?? [],
      }),
    );
  }, [geoData, market, addFeatures]);

  const highlightFeature = useCallback(
    (index: number) => {
      highlight(index);
    },
    [highlight],
  );

  return {
    features,
    featureSlotsIndexes,
    tags,
    highlightedFeature: highlightedFeatureIndex,
    highlightFeature,
  };
};

export { useFeatures };
