import PropTypes from 'prop-types';
import { Heading, Text } from '@carvertical/ui';
import EnvelopeCheckCircleIcon from 'assets/icons/envelope-check-circle.svg';
import Page from './Page';
import styles from './EmailPage.module.scss';

const EmailPage = ({ title, text, children }) => (
  <Page className={styles.root} title={title} breadcrumbs={false}>
    <div className={styles.content}>
      <EnvelopeCheckCircleIcon className={styles.icon} />

      <Heading as="h2" variant="l" align="center" className="mb-2">
        {title}
      </Heading>

      <Text variant="m" align="center">
        {text}
      </Text>

      {children}
    </div>
  </Page>
);

EmailPage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
};
EmailPage.defaultProps = {
  text: undefined,
  children: undefined,
};

export default EmailPage;
