import { map } from 'lodash';
import { Container } from '@carvertical/ui';
import { useTranslation, type TFunction } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { CompactCallToActionSection } from 'modules/shared';
import { useFeatures } from '../../hooks';
import { FeatureArticle } from './FeatureArticle';
import { VerifiedReview } from './VerifiedReview';

const FeaturesList = () => {
  const { t } = useTranslation();
  const { features, featureSlotsIndexes } = useFeatures();
  const articlesRef = useRef<(HTMLDivElement | null)[]>([]);
  const slots = getSlots(t);
  let slotIndex = 0;

  useScrollToFeature(articlesRef);

  const addArticleRef = (ref: HTMLDivElement | null, index: number) => {
    if (ref) {
      articlesRef.current[index] = ref;
    }
  };

  return (
    <div className="flex flex-col gap-0 md:gap-9">
      {map(features, (feature) => {
        if (!feature) {
          return null;
        }

        if (featureSlotsIndexes?.includes(feature.index)) {
          const slot = slots[slotIndex];
          slotIndex += 1;

          return (
            <div className="flex flex-col gap-0 md:gap-9" key={feature.id}>
              <Container>
                <FeatureArticle
                  {...feature}
                  ref={(el: HTMLDivElement | null) => addArticleRef(el, feature.index)}
                />
              </Container>

              {slot}
            </div>
          );
        }

        return (
          <Container key={feature.id}>
            <FeatureArticle
              {...feature}
              ref={(el: HTMLDivElement | null) => addArticleRef(el, feature.index)}
            />
          </Container>
        );
      })}
    </div>
  );
};

// FIXME: https://carvertical.atlassian.net/browse/FE-12
function getSlots(t: TFunction): React.ReactNode[] {
  return [
    <VerifiedReview
      key="0"
      // @ts-expect-error TS(2322)
      authorKey="verifiedReviews.0.author"
      // @ts-expect-error TS(2322)
      quoteKey="verifiedReviews.0.review"
      mascotsVariant="1"
    />,
    <div key="1" className="flex flex-col items-stretch">
      <VerifiedReview
        // @ts-expect-error TS(2322)
        authorKey="verifiedReviews.1.author"
        // @ts-expect-error TS(2322)
        quoteKey="verifiedReviews.1.review"
        mascotsVariant="2"
      />

      <CompactCallToActionSection
        // @ts-expect-error TS(2322)
        title={t('featuresPage.cta.1.title')}
        // @ts-expect-error TS(2322)
        subtitle={t('featuresPage.cta.1.subtitle')}
        textClassName="text-left sm:max-lg:text-center sm:max-lg:max-w-96"
        className="p-0"
        trustBannerShown={false}
      />
    </div>,
    <VerifiedReview
      key="2"
      // @ts-expect-error TS(2322)
      authorKey="verifiedReviews.2.author"
      // @ts-expect-error TS(2322)
      quoteKey="verifiedReviews.2.review"
      mascotsVariant="3"
    />,
    <VerifiedReview
      key="2"
      // @ts-expect-error TS(2322)
      authorKey="verifiedReviews.3.author"
      // @ts-expect-error TS(2322)
      quoteKey="verifiedReviews.3.review"
      mascotsVariant="4"
    />,
  ];
}

function useScrollToFeature(articlesRef: React.MutableRefObject<(HTMLDivElement | null)[]>) {
  const { highlightFeature } = useFeatures();

  useEffect(() => {
    let scrollAnimationId: number;

    const checkVisibility = () => {
      let maxVisibleHeight = 0;

      scrollAnimationId = requestAnimationFrame(() => {
        const articles = articlesRef.current?.filter(Boolean) ?? [];
        const windowHeight = window.innerHeight;

        articles.forEach((article, index) => {
          const rect = article.getBoundingClientRect();
          const top = Math.max(0, rect.top);
          const bottom = Math.min(windowHeight, rect.bottom);
          const visibleHeight = Math.max(0, bottom - top);

          if (visibleHeight > maxVisibleHeight) {
            maxVisibleHeight = visibleHeight;
            highlightFeature(index);
          }
        });
      });
    };

    window.addEventListener('scroll', checkVisibility);

    return () => {
      window.removeEventListener('scroll', checkVisibility);
      cancelAnimationFrame(scrollAnimationId);
    };
  }, [highlightFeature, articlesRef]);
}

export { FeaturesList };
