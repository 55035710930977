import { Badge, Container } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { cn } from '@carvertical/utils/styling';
import type { ParseKeys } from 'i18next';
import type { SVGIllustration } from 'modules/shared';
import QuotationMarkIllustration from 'assets/illustrations/quotation-mark.svg';
import MascotBlue1Illustration from '../../assets/mascot-blue-1.svg';
import MascotBlue2Illustration from '../../assets/mascot-blue-2.svg';
import MascotBlue3Illustration from '../../assets/mascot-blue-3.svg';
import MascotBlue4Illustration from '../../assets/mascot-blue-4.svg';
import MascotYellow1Illustration from '../../assets/mascot-yellow-1.svg';
import MascotYellow2Illustration from '../../assets/mascot-yellow-2.svg';
import MascotYellow3Illustration from '../../assets/mascot-yellow-3.svg';
import MascotYellow4Illustration from '../../assets/mascot-yellow-4.svg';
import MascotGroup1Illustration from '../../assets/mascot-group-1.svg';
import MascotGroup2Illustration from '../../assets/mascot-group-2.svg';
import MascotGroup3Illustration from '../../assets/mascot-group-3.svg';
import MascotGroup4Illustration from '../../assets/mascot-group-4.svg';

type MascotsVariant = '1' | '2' | '3' | '4';
type Mascot = 'blue' | 'yellow';

type MascotProps = {
  illustration: SVGIllustration;
  type: Mascot;
};

type VerifiedReviewProps = {
  authorKey: ParseKeys;
  quoteKey: ParseKeys;
  mascotsVariant?: MascotsVariant;
};

const LEADING_MASCOT_MAP: Record<MascotsVariant, MascotProps> = {
  '1': {
    illustration: MascotBlue1Illustration,
    type: 'blue',
  },
  '2': {
    illustration: MascotYellow2Illustration,
    type: 'yellow',
  },
  '3': {
    illustration: MascotBlue3Illustration,
    type: 'blue',
  },
  '4': {
    illustration: MascotYellow4Illustration,
    type: 'yellow',
  },
};

const TRAILING_MASCOT_MAP: Record<MascotsVariant, MascotProps> = {
  '1': {
    illustration: MascotYellow1Illustration,
    type: 'yellow',
  },
  '2': {
    illustration: MascotBlue2Illustration,
    type: 'blue',
  },
  '3': {
    illustration: MascotYellow3Illustration,
    type: 'yellow',
  },
  '4': {
    illustration: MascotBlue4Illustration,
    type: 'blue',
  },
};

const GROUP_MASCOT_MAP: Record<MascotsVariant, SVGIllustration> = {
  '1': MascotGroup1Illustration,
  '2': MascotGroup2Illustration,
  '3': MascotGroup3Illustration,
  '4': MascotGroup4Illustration,
};

const VerifiedReview = ({ authorKey, quoteKey, mascotsVariant = '1' }: VerifiedReviewProps) => {
  const { t } = useTranslation();
  const leadingMascot = LEADING_MASCOT_MAP[mascotsVariant];
  const trailingMascot = TRAILING_MASCOT_MAP[mascotsVariant];

  const LeadingMascotIllustration = leadingMascot.illustration;
  const TrailingMascotIllustration = trailingMascot.illustration;
  const GroupMascotIllustration = GROUP_MASCOT_MAP[mascotsVariant];

  return (
    <div className="flex">
      <Container className="flex flex-col items-center gap-5 max-md:pb-3 max-md:pt-8 md:flex-row md:items-end md:gap-8 md:self-center">
        <LeadingMascotIllustration className={getMascotClassName(leadingMascot.type)} original />

        <div className="flex max-w-52 flex-col gap-1.5 self-center rounded-s border border-solid border-blue-100 bg-blue-50 p-3">
          <div className="flex items-start justify-between gap-2">
            <QuotationMarkIllustration className="h-6 w-6 text-blue-400" original />

            <Badge variant="blue" size="s" icon="check">
              <span className="text-xs+">{t('userReviews.verifiedReview')}</span>
            </Badge>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-m+">{t(quoteKey)}</p>

            <p className="text-s+ text-blue-500">
              {/* @ts-expect-error TS(2339) */}
              <Trans t={t} i18nKey={authorKey} components={{ 1: <span className="text-s+" /> }} />
            </p>
          </div>
        </div>

        <div className="flex">
          <TrailingMascotIllustration
            className={getMascotClassName(trailingMascot.type)}
            original
          />

          <GroupMascotIllustration className="w-26 h-28 md:!hidden" original />
        </div>
      </Container>
    </div>
  );
};

function getMascotClassName(type: Mascot) {
  const mascotBaseClassName = cn('max-md:!hidden max-md:max-w-20 max-sm:max-w-15');
  const mascotBlueClassName = cn(mascotBaseClassName, 'h-32');
  const mascotYellowClassName = cn(mascotBaseClassName, 'h-28');

  return type === 'blue' ? mascotBlueClassName : mascotYellowClassName;
}

export { VerifiedReview };
