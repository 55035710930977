import { useCallback } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import type { BusinessPagePartialRouteData, CampaignPartnerId } from '../types';
import { CAMPAIGN_CONTENT } from '../constants';
import { useCampaignActions, useCampaignSuccessful } from './useCampaignStore';

const useCampaign = () => {
  const { partnerId } = useRouteData<BusinessPagePartialRouteData>();
  const campaignSuccessful = useCampaignSuccessful();
  const { setCampaignSuccessful } = useCampaignActions();

  const campaignContent = CAMPAIGN_CONTENT[partnerId as CampaignPartnerId];

  const markCampaignAsSuccessful = useCallback(() => {
    setCampaignSuccessful(true);
  }, [setCampaignSuccessful]);

  return {
    campaignContent,
    campaignSuccessful,
    markCampaignAsSuccessful,
    partner: partnerId,
  };
};

export { useCampaign };
